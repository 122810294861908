<template>
  <!-- 项目详情 -->
  <div style="padding-top: 77px">
    <div class="project-detail">
      <div class="base-info">
        //上传图片和查看图片
        <upLook v-if="isShow" @closeDialog="closeDialog"  ref="UpLook"></upLook>

        <!-- <span>成交</span> -->
        <template>
          <div
            class="carousel"
            v-if="projectInfo.xctpList && projectInfo.xctpList.length > 0"
          >
            <swiper
              class="gallery-top"
              ref="swiperTop"
              :options="swiperOptions"
            >
              <swiper-slide
                v-for="(item, index) in projectInfo.xctpList"
                :key="index"
              >
                <img :src="item.url" alt="" />
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>

            <swiper
              class="gallery-thumbs"
              ref="swiperThumbs"
              :options="swiperOptionThumbs"
            >
              <swiper-slide
                v-for="(item, index) in projectInfo.xctpList"
                :key="index"
              >
                <img :src="item.url" alt="" />
              </swiper-slide>
            </swiper>
          </div>
          <div class="carousel" v-else>
            <img src="imgs/none.png" alt="" />
          </div>
        </template>
        <div class="detail">
          <div class="title">
            <span>{{ projectInfo.xmmc ? projectInfo.xmmc : "" }}</span>
          </div>
          <p>交易方式：{{ projectInfo.jyfs ? projectInfo.jyfs : "无" }}</p>
          <p>流转年限：{{ projectInfo.lznx ? projectInfo.lznx + "年" : "" }}</p>
          <p>详细地址：{{ projectInfo.xmdz }}</p>
          <p>发布时间：{{ projectInfo.fbsj ? projectInfo.fbsj : "" }}</p>
        </div>
        <div class="apply-status">
          <el-button type="primary" @click="lookHeTong('123',1)">上传合同</el-button>
          <el-button type="primary" @click="lookHeTong('123',2)">查看合同</el-button>
        </div>
        <span class="goback" @click="goback">返回 ></span>
      </div>

      <div>
        <div class="table-con">
          <div class="define-table" style="border-top: 4px solid #18d531">
            <table style="table-layout: fixed">
              <tr>
                <td class="name">流出方/流出方农户代表人</td>
                <td class="value">王潇锋</td>
                <td class="value">联系方式</td>
                <td class="name">1541515151515</td>
              </tr>
              <tr>
                <td class="name">流入方方</td>
                <td class="value">王潇锋</td>
                <td class="value">联系方式</td>
                <td class="name">9999999999999999</td>
              </tr>


            </table>
          </div>
        </div>
        <div class="table-con">
          <div class="table-title">
            <span>转出信息</span>
          </div>
          <div class="define-table">
            <table style="table-layout: fixed">
              <tr>
                <td class="name">产权类型</td>
                <td class="value">{{ cqlx(projectInfo.cqlxbh)}}</td>
                <td class="name">是否属再流转</td>
                <td class="value" v-if="projectInfo.sfsyzlz != null">
                  {{ projectInfo.sfsyzlz == "0" ? "否" : "是" }}
                  <template v-if="projectInfo.sfsyzlz == '1'">
                    <span
                      class="download-btn"
                      v-for="(item, index) in projectInfo.zlzzmList"
                      :key="index"
                      @click="download(item)"
                    >
                      {{ "(" + item.name + ")" }}
                    </span>
                  </template>
                </td>
              </tr>
              <tr>
                <td class="name">挂牌底价</td>
                <td class="value">
                  {{
                    projectInfo.gpjg ? projectInfo.gpjg.toFixed(2) + "元" : ""
                  }}
                </td>
                <td class="name">付款方式</td>
                <td class="value">{{ projectInfo.fkfs }}</td>
              </tr>
              <tr>
                <td class="name">面积</td>
                <td class="value">
                  {{
                    projectInfo.zcmj +
                    (projectInfo.mjdw == "1" ? "亩" : "平方米")
                  }}
                </td>

                <td class="name">位置</td>
                <td class="value">{{ projectInfo.xmdz }}</td>
              </tr>
              <tr>
                <td class="name">流转方式</td>
                <td class="value">{{ getLzlx(projectInfo.lzlx) }}</td>
                <td class="name">流转年限</td>
                <td class="value">
                  {{ projectInfo.lznx ? projectInfo.lznx + "年" : "" }}
                </td>
              </tr>

              <tr>
                <td class="name" v-if="projectInfo.jyfs == '在线竞价交易' && projectInfo.cqlxbh == '01'">受让方条件</td>
                <td class="value" v-if="projectInfo.jyfs == '在线竞价交易' && projectInfo.cqlxbh == '01'">面向已缴纳完意向保证金的社会人士</td>
                <td class="name">其他说明</td>
                <td class="value" :colspan="!(projectInfo.jyfs == '在线竞价交易' && projectInfo.cqlxbh == '01') ? '3' :'1'">
                  {{ projectInfo.qtsm ? projectInfo.qtsm : "" }}
                </td>
              </tr>

            </table>
            <table style="table-layout: fixed" class="nmlist">
            <tr><td colspan="4">农户清单</td></tr>
            <tr>
              <td>姓名</td>
              <td>身份证号</td>
              <td>流转面积</td>
              <td>联系方式</td>
            </tr>
            <tr v-for="item in farmersList " :key="item.identityId">
              <td>{{ item.farmerName }}</td>
              <td>{{ item.identityId }}</td>
              <td>{{item.area}}</td>
              <td>{{ item.phoneNumber }}</td>
            </tr>
          </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getXmxxDetail } from "../../api/exchange";
import { getLzlx, getCqlx } from "../../util/types";
import upLook from './upLook'
export default {
  name: "lzsp",
  components: {
    upLook
  },
  mixins: [],
  props: {},

  data() {
    return {
      projectInfo: {},
      isShow: false,
      dialogVisible: false, //导出合同组件显示判断条件
    };
  },

  computed: mapGetters(["userInfo", "access_token", "identityObj"]),
  watch: {},
  mounted() {
    this.initData()
  },
  methods: {
       // 获取流转类型
       getLzlx(type) {
      let res = "";
      if (type == 0) {
        res = "出租";
      } else if (type == 1) {
        res = "入股";
      } else if (type == 2) {
        res = "转让";
      } else if (type == 3) {
        res = "出让";
      }
      return res;
    },

    cqlx(type) {
      console.log("type",type);
      let list = JSON.parse(localStorage.getItem("types"));
      let res = "";
      console.log(list);
      if (list && list.length > 0) {
        // list.forEach((x) => {
        //   console.log("x",x);
        //   if (x.bh == type) {
        //     res = x.name;
        //   }
        // });
        let res = getCqlx(type, list);
        console.log(res);
        return res;
      } else {
        return "";
      }
    },
    async initData() {
      // 请求详情数据
      let params = {
        xmbh: this.$route.query.c,
      };
      //这里是拿到的所有的数据
      let res = await getXmxxDetail(params);
      // console.log("-----------------",res.data.cqlxbh);
      let { status, data } = res;
      // console.log("wxf",data.cqlxbh);
      // // data.cqlxbh=this.cqlx(data.cqlxbh)
      // console.log("data.cqlxbh",data.cqlxbh);
      if (status == 200) {
        this.projectInfo = data;
      }
    },
    // 返回
    goback() {
      if (sessionStorage.getItem("fromZnxx")) {
        sessionStorage.removeItem("fromZnxx");
        this.$router.go(-2);
      } else {
        this.$router.go(-1);
      }
    },
    //打开弹窗
    lookHeTong(obj,isUp){
      this.isShow= true
      this.$nextTick(() => {
        this.$refs.UpLook.Init(obj,isUp);
      });
    },
    closeDialog(){
      this.isShow = false;
    }
  },
};
</script>

<style lang="less" scoped>
.nmlist{

        width: 100%;
        text-align: center;
        border: 1px solid #c9c9c9;
        border-bottom: none;
        tr {
          min-height: 40px;
          height: 40px;
          width: 100%;
          td {
            border-left: 1px solid #c9c9c9;
            border-right: 1px solid #c9c9c9;
            border-bottom: 1px solid #c9c9c9;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

}
.project-detail {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  text-align: left;
  padding-bottom: 128px;
  .base-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 41px 74px 0 74px;
    position: relative;
    .carousel {
      width: 320px;
      height: 300px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .gallery-top {
      height: 200px;
    }
    .gallery-thumbs {
      margin-top: 20px;
      .swiper-slide {
        width: 100px;
        height: 75px;
      }
    }
    .detail {
      margin-left: 28px;
    }
    p {
      margin: 0;
      line-height: 34px;
    }
    .title {
      font-family: MicrosoftYaHei-Bold;
      font-size: 22px;
      line-height: 40px;
      margin-bottom: 24px;
    }
    .apply-status {
      text-align: right;
      margin-left: 100px;
      align-self: flex-end;
      .el-button {
        margin-bottom: 20px;
        width: 127px;
        height: 37px;
        padding: 0 12px;
      }
    }
    .goback {
      position: absolute;
      right: 74px;
      top: 50px;
      color: #00a0e9;
      cursor: pointer;
    }
  }
  .table-con {
    margin-top: 20px;
    padding: 18px;
    .table-title {
      height: 54px;
      line-height: 54px;
      border: solid 1px #c9c9c9;
      border-top: 4px solid #18d531;
      padding-left: 19px;
    }
    .table-bottom {
      padding: 18px 30px;
      border: solid 1px #c9c9c9;
      border-top: none;
      display: flex;
      flex-flow: column;
      gap: 20px;
      > span {
        display: block;
      }
      > button {
        width: 200px;
        margin: 0 auto;
      }
    }
    .define-table {
      width: 100%;
      table {
        width: 100%;
        text-align: center;
        border: 1px solid #c9c9c9;
        border-bottom: none;
        tr {
          min-height: 40px;
          height: 40px;
          width: 100%;
          td {
            border-left: 1px solid #c9c9c9;
            border-right: 1px solid #c9c9c9;
            border-bottom: 1px solid #c9c9c9;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .value {
          width: 300px;
          border-right: none;
        }
        .name {
          width: 270px;
          border-right: none;
        }
        .name:first-child {
          border-left: none;
        }

        .special {
          width: 885px;
          height: 44px;
          border-left: 1px solid #c9c9c9;
          border-right: 1px solid #c9c9c9;
          .special-td {
            width: 410px;
            height: 36px;
            line-height: 36px;
            span {
              display: inline-block;
              width: 300px;
              line-height: 18px;
              padding-top: 10px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .special-td-2 {
            width: 475px;
            height: 36px;
            line-height: 36px;
            span {
              display: inline-block;
              width: 300px;
              padding-top: 10px;
              line-height: 18px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
.download-btn {
  cursor: pointer;
}
.img {
  display: inline-block;
  max-height: 320px;
}
</style>

<style lang="less">
.carousel {
  .el-carousel__container {
    height: 100%;
  }
}
.disUoloadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
</style>
